import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

// Define a type for the slice state
export interface InitialState {
  hashedPassword: string | null;
}

// Define the initial state using that type
const initialState: InitialState = {
  hashedPassword: null
};

// `createSlice` will infer the state type from the `initialState` argument
export const commonDataSlice = createSlice({
  name: "common",
  initialState,
  reducers: {

    setHashedPassword(state, action: PayloadAction<InitialState["hashedPassword"]>) {
      state.hashedPassword = action.payload;
    }
  }
});

export const actions = commonDataSlice.actions;
export const state = (state: RootState) => state.common;
export default commonDataSlice.reducer;
