import React from "react";

export function IconsCollection() {
  /*
   * Icons here
   * I take icons from iconmonstr.com but you can them from anywhere (for example: svgrepo.com)
   *  */

  return (
    <svg
      viewBox="0 0 24 24"
      style={{display: "none"}}
    >
      <symbol
        id="menu-left-lined"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path
          d="m13 16.745c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm9-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-4-5c0-.414-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75h14.5c.414 0 .75-.336.75-.75z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol
        id="shape-10"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path d="M19 0h-14c-2.76 0-5 2.239-5 5v14c0 2.76 2.239 5 5 5h14c2.759 0 5-2.239 5-5v-14c0-2.76-2.239-5-5-5m0 2c1.66 0 3 1.345 3 3v14c0 1.654-1.338 3-3 3h-14c-1.654 0-3-1.339-3-3v-14c0-1.658 1.342-3 3-3h14z" />
      </symbol>

      <symbol id="checkbox-9">
        <path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" />
      </symbol>

      <symbol id="circle-2">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
      </symbol>

      <symbol id="checkbox-28">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 6c-3.313 0-6 2.687-6 6s2.687 6 6 6c3.314 0 6-2.687 6-6s-2.686-6-6-6z" />
      </symbol>

      <symbol id="arrow-65">
        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
      </symbol>

      <symbol
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        id="x-mark-circle-lined"
      >
        <path
          d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol id="check-mark-circle-lined">
        <path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" />
      </symbol>

      <symbol
        id="error-lined"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z" />
      </symbol>

      <symbol
        id="info-lined"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path
          d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol id="lock-14">
        <path d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-4v14h18v-14h-12zm10 12h-14v-10h14v10z" />
      </symbol>

      <symbol id="log-out-3">
        <path d="M8 9v-4l8 7-8 7v-4h-8v-6h8zm6-7c-1.787 0-3.46.474-4.911 1.295l.228.2 1.395 1.221c1.004-.456 2.115-.716 3.288-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.284-.26-3.288-.715l-1.395 1.221-.228.2c1.451.82 3.124 1.294 4.911 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z" />
      </symbol>

      <symbol id="user-31">
        <path d="M10.644 17.08c2.866-.662 4.539-1.241 3.246-3.682-3.932-7.427-1.042-11.398 3.111-11.398 4.235 0 7.054 4.124 3.11 11.398-1.332 2.455.437 3.034 3.242 3.682 2.483.574 2.647 1.787 2.647 3.889v1.031h-18c0-2.745-.22-4.258 2.644-4.92zm-12.644 4.92h7.809c-.035-8.177 3.436-5.313 3.436-11.127 0-2.511-1.639-3.873-3.748-3.873-3.115 0-5.282 2.979-2.333 8.549.969 1.83-1.031 2.265-3.181 2.761-1.862.43-1.983 1.34-1.983 2.917v.773z" />
      </symbol>

      <symbol id="chart-5">
        <path d="M7 24h-6v-6h6v6zm8-9h-6v9h6v-9zm8-4h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z" />
      </symbol>

      <symbol
        id="layout-table-filled"
        clipRule="evenodd"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path
          d="m3 12.7v7.3c0 .62.519 1 1 1h7.3v-8.3zm18 0h-8.3v8.3h7.3c.621 0 1-.52 1-1zm-9.7-9.7h-7.3c-.478 0-1 .379-1 1v7.3h8.3zm1.4 0v8.3h8.3v-7.3c0-.478-.379-1-1-1z"
          fillRule="nonzero"
        />
      </symbol>

      <symbol id="home-6">
        <path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" />
      </symbol>

      <symbol id="bed-1" fillRule="evenodd" clipRule="evenodd">
        <path d="M24 19v-7h-23v-7h-1v14h1v-2h22v2h1zm-20-12c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm19 4c0-1.657-1.343-3-3-3h-13v3h16z" />
      </symbol>

      <symbol id="thumb-18">
        <path
          d="M12.352 2.107c2.481 1.759 2.474 1.736 5.493 1.718.244 0 .459.136.524.331.917 2.789.942 2.838 3.438 4.554.176.122.227.307.174.453-.971 2.801-.979 2.834 0 5.676.053.145.003.33-.175.453-2.469 1.699-2.505 1.73-3.437 4.553-.065.195-.28.331-.526.331-2.995-.019-3-.049-5.49 1.717-.205.145-.501.144-.704 0-2.475-1.751-2.463-1.739-5.493-1.717-.244 0-.459-.136-.523-.329-.826-2.499-.907-2.82-2.925-4.202l-.514-.354c-.176-.122-.227-.307-.174-.453.866-2.504.999-2.805.193-5.114-.146-.424-.374-.769-.019-1.016 2.458-1.691 2.506-1.722 3.437-4.553.065-.195.28-.331.526-.331 3.009.021 2.996.048 5.491-1.717.206-.145.503-.141.704 0zm-.352-2.107c-.527 0-1.055.157-1.502.471-1.757 1.236-1.77 1.362-3.152 1.362l-1.183-.008h-.008c-1.104 0-2.083.685-2.421 1.696-.812 2.433-.533 2.055-2.68 3.544-.675.468-1.054 1.212-1.054 1.982 0 .254.041.512.127.763.83 2.428.827 1.963 0 4.38-.086.251-.127.508-.127.763 0 .77.379 1.514 1.055 1.982 2.147 1.489 1.869 1.114 2.68 3.544.338 1.011 1.316 1.696 2.421 1.696h.008c2.652-.008 2.189-.155 4.335 1.354.446.313.974.471 1.501.471s1.055-.157 1.502-.471c1.76-1.238 1.762-1.361 3.181-1.361l1.154.007h.008c1.104 0 2.083-.685 2.421-1.696.812-2.428.528-2.053 2.68-3.544.675-.469 1.054-1.212 1.054-1.982 0-.254-.041-.512-.127-.763-.831-2.428-.827-1.963 0-4.38.086-.251.127-.509.127-.763 0-.77-.379-1.514-1.055-1.982-2.152-1.492-1.868-1.117-2.68-3.544-.338-1.011-1.316-1.696-2.421-1.696h-.008l-1.156.007c-1.416 0-1.42-.124-3.179-1.361-.446-.314-.974-.471-1.501-.471zm3.698 15.354c-.405-.031-.367-.406.016-.477.634-.117.913-.457.913-.771 0-.265-.198-.511-.549-.591-.418-.095-.332-.379.016-.406.566-.045.844-.382.844-.705 0-.282-.212-.554-.63-.61-.429-.057-.289-.367.016-.461.261-.08.677-.25.677-.755 0-.336-.25-.781-1.136-.745-.614.025-1.833-.099-2.489-.442.452-1.829.343-4.391-.845-4.391-.797 0-.948.903-1.188 1.734-.859 2.984-2.577 3.531-4.343 3.802v4.964c3.344 0 4.25 1.5 6.752 1.5 1.6 0 2.426-.867 2.426-1.333 0-.167-.136-.287-.48-.313z"
        />
      </symbol>
    </svg>
  );
}

export default function Icon({icon}: {icon: string}) {
  // Render
  return (
    <svg
      viewBox="0 0 24 24"
      className="Icon"
    >
      <use xlinkHref={`#${icon}`} />
    </svg>
  );
}
