import React, {useState} from "react";

import styles from "./index.module.css";
import QuizItemModel from "../../models/QuizItem";
import useInfiniteScroll from "react-infinite-scroll-hook";
import axiosClient from "../../helpers/axiosClient";
import onError from "../../helpers/onError";
import Loader from "../../components/Loader";
import classNames from "classnames";

function toHuman(answer: "Perfect" | "Good" | "Bad"): string {

  if(answer === "Perfect")
    return "Отлично 😍";

  else if(answer === "Good")
    return "Хорошо 👌";

  else if(answer === "Bad")
    return "Плохо 😡";

  return answer;
}

export default function QuizPage() {

  // State
  const [quizItems, setQuizItems] = useState<QuizItemModel[]>([]);
  const [isFetchingQuizItemsDisabled, setIsFetchingQuizItemsDisabled] = useState<boolean>(false);
  const [isFetchingQuizItemsPending, setIsFetchingQuizItemsPending] = useState<boolean>(false);
  const [isFetchedAllQuizItems, setIsFetchedAllQuizItems] = useState<boolean>(false);

  // Infinite scroll
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isFetchingQuizItemsPending,
    hasNextPage: !isFetchedAllQuizItems,
    onLoadMore: loadMoreQuizItems,
    disabled: isFetchingQuizItemsDisabled
  });

  // Load more quiz items function
  function loadMoreQuizItems() {

    if(isFetchingQuizItemsPending) return;
    setIsFetchingQuizItemsPending(true);

    return axiosClient.get<QuizItemModel[]>("/quiz/getList", {
      params: {
        offset: quizItems.length,
      }
    })
      .then(({data: quizItems}) => {

        if(!quizItems.length) return setIsFetchedAllQuizItems(true);

        setQuizItems((oldQuizItems) => ([...oldQuizItems, ...quizItems]));
      })
      .catch((error) => {
        onError(error);
        setIsFetchingQuizItemsDisabled(true);
      })
      .finally(() => {
        setIsFetchingQuizItemsPending(false);
      });
  }

  // Render
  return (
    <div className="page" style={{maxWidth: "unset"}}>
      <h1>Опросы</h1>

      <div className={styles.quizItems}>
        {quizItems.map((quizItem) => (
          <div key={quizItem._id} className={styles.quizItem}>

            <h3>{quizItem.displayedName || <i>(Имя не указано)</i>}</h3>
            <h4>{quizItem.phone || <i>(Телефон не указан)</i>}</h4>

            {quizItem.questions.map((question, i) => (
              <React.Fragment key={question.question.concat(String(i))}>
                <div className={styles.question}>
                  {question.question}
                </div>

                <div className={styles["question-answer"]}>{toHuman(question.answer)}</div>

                <div className={styles["question-comment"]}>
                  {question.text}
                </div>

                {question.answer !== "Perfect" ? (
                  <div className={styles["question-bubbles"]}>
                    {question.bubbles.map((bubble) => (<React.Fragment key={bubble.id}>
                      <div
                        title={bubble.comment.text} key={bubble.id} className={classNames({
                        _isActive: bubble.isSelected,
                        [styles["question-bubble"]]: true
                      })}
                      >{bubble.title}</div>
                      {bubble.title ? (
                        <div className={styles["bubble-text"]}>
                          {bubble.comment.text}
                        </div>
                      ) : null}
                    </React.Fragment>))}
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>

      {(isFetchingQuizItemsPending || !isFetchedAllQuizItems && !isFetchingQuizItemsDisabled) && (
        <div ref={infiniteScrollRef} className="loading-wheel-wrapper">
          <Loader color="#aaa" size={30} width={3} spinsPerSecond={3} />
        </div>
      )}
    </div>
  );
}
