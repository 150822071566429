import {AxiosError} from "axios";
import consola from "consola";
import {DateTime} from "luxon";
import {createNotification} from "./notifications";

export default function onError(error: Error | AxiosError | unknown) {
  let errorMessage = `Unknown error (${error})`;
  let errorCode: number | null = null;

  if(error instanceof Error) {
    errorMessage = error.message ?? String(error);
  }

  if(error instanceof AxiosError) {
    if(error.response) {
      errorCode = error.response.status;

      if(errorCode === 400) {
        errorMessage = 'Проверьте правильность введённых данных';
      }
      else if(errorCode === 401) {
        errorMessage = 'Неуспешная авторизация';
      }
      else {
        errorMessage = error.response.data;
      }
    }
    else {
      errorMessage = error.message;
    }
  }

  consola.error(error);
  createNotification({
    icon: "error-lined",
    // eslint-disable-next-line no-undefined
    expireDate: DateTime.now().plus({seconds: 5}).toISO() ?? undefined,
    title: `Ошибка${errorCode ? ` #${errorCode}` : ""}`,
    contents: errorMessage
  });
}
