import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {Navigate, NavLink, Route, Routes, useLocation} from "react-router-dom";
import config from "../../config";
import axiosClient from "../../helpers/axiosClient";
import {AuthPage} from "../../pages/auth";
import BookingsPage from "../../pages/bookings";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import * as commonState from "../../store/reducers/common";
import Icon from "../Icon";
import styles from "./index.module.css";
import QuizPage from "../../pages/quiz";

export default function App() {

  // State
  const {hashedPassword} = useAppSelector(commonState.state);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  // Hooks
  const dispatch = useAppDispatch();
  const location = useLocation();

  // States
  const [isSidebarShownShown, setSideBarShown] = useState<boolean>(
    window.innerWidth > 580
  );

  // Generate color shades to CSS variables
  useEffect(() => {
    const shades = [
      10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700,
      800, 900, 1000
    ];

    const accentColorShades = [
      "#EBEEFD",
      "#D6DCFB",
      "#C2CBF9",
      "#AEBAF7",
      "#9AA9F5",
      "#8597F3",
      "#7186F1",
      "#5D75EF",
      "#4863ED",
      "#3452EB",
      "#2F4AD4",
      "#2A42BC",
      "#2439A5",
      "#1F318D",
      "#1A2976",
      "#15215E",
      "#101947",
      "#0A102F",
      "#050818"
    ];

    const blackColorShades = [
      "#F2F2F2",
      "#E6E6E6",
      "#D9D9D9",
      "#CCCCCC",
      "#C0C0C0",
      "#B3B3B3",
      "#A6A6A6",
      "#999999",
      "#8D8D8D",
      "#808080",
      "#737373",
      "#666666",
      "#5A5A5A",
      "#4D4D4D",
      "#404040",
      "#333333",
      "#262626",
      "#1A1A1A",
      "#0D0D0D"
    ];

    shades.forEach((shade, i) =>
      document.body.style.setProperty(
        `--accent-color-${shade}`,
        accentColorShades[i]
      )
    );

    shades.forEach((shade, i) =>
      document.body.style.setProperty(
        `--black-color-${shade}`,
        blackColorShades[i]
      )
    );
  }, []);

  // Close mobile sidebar on page change
  useEffect(() => {
    if(window.innerWidth < 580) {
      setSideBarShown(false);
    }
  }, [location]);

  // Provide authentication token
  useEffect(() => {
    axiosClient.defaults.headers["Authorization"] = `Bearer ${hashedPassword}`;

    setIsPageLoaded(true);
  }, [hashedPassword]);

  // Render
  return location.pathname === "/auth" || hashedPassword ? (isPageLoaded ? (
      <>
        {/* Header */}
        <header className={styles.header}>
          {hashedPassword ? (
            <button
              className={classNames("is-zeroed", styles["sidebar-switcher-button"])}
              onClick={() => setSideBarShown(!isSidebarShownShown)}
            >
              <Icon icon="menu-left-lined" />
            </button>
          ) : null}

          <h1>{config.SITE_NAME}</h1>

          {hashedPassword ? (
            <button
              className={classNames("is-zeroed", styles["right-button"])}
              onClick={() => {
                dispatch(commonState.actions.setHashedPassword(null));
              }}
            >
              <Icon icon="log-out-3" />
              <span>Выйти</span>
            </button>
          ) : null}
        </header>

      {/* Main */}
      <div className={styles["layout-container"]}>
        <aside
          className={classNames({
            _isHidden: !isSidebarShownShown || !hashedPassword
          })}
        >
          <nav>
            <NavLink to="/bookings">
              <Icon icon="user-31" />
              <span>Брони</span>
            </NavLink>

            <NavLink to="/quiz">
              <Icon icon="thumb-18" />
              <span>Опросы</span>
            </NavLink>
          </nav>
        </aside>

        {/* Main element */}
        <main>
          <Routes>
            <Route
              path="/auth"
              element={<AuthPage />}
            />
            <Route
              path="/bookings"
              element={<BookingsPage />}
            />
            <Route
              path="/quiz"
              element={<QuizPage />}
            />
            <Route
              path=""
              element={<Navigate to="/bookings" />}
            />
          </Routes>
        </main>
      </div>

        {/* Mobile sidebar darker */}
        <div
          className={classNames(styles["mobile-sidebar-darker"], {
            _isHidden: !isSidebarShownShown
          })}
          onClick={() => setSideBarShown(false)}
        />
      </>
    ) : null
  ) : (<Navigate to="/auth" />);
}
