import classNames from "classnames";
import {DateTime} from "luxon";
import React, {FormEvent, useState} from "react";
import {Navigate} from "react-router-dom";
import Icon from "../../components/Icon/";
import Loader from "../../components/Loader";
import axiosClient from "../../helpers/axiosClient";
import {createNotification} from "../../helpers/notifications";
import onError from "../../helpers/onError";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import * as commonStore from "../../store/reducers/common";
import styles from "./index.module.css";

export function AuthPage() {

  const dispatch = useAppDispatch();
  const {hashedPassword} = useAppSelector(commonStore.state);

  const [isFormPending, setFormPending] = useState(false);

  // Auth form handler
  function onAuth(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if(isFormPending) {
      return false;
    }

    setFormPending(true);

    const username = new FormData(e.currentTarget).get("username");
    const password = new FormData(e.currentTarget).get("password");

    if(!username || !password) {
      setFormPending(false);
      createNotification({
        title: "Error",
        contents: "Incorrect password",
        icon: "error-lined",
        // eslint-disable-next-line no-undefined
        expireDate: DateTime.now().plus({second: 5}).toISO() ?? undefined
      });

      return false;
    }

    setTimeout(() => {
      axiosClient.post<string>("/auth", {username, password})
      .then(({data}) => {
        createNotification({
          title: "Info",
          contents: "Успешная авторизация",
          icon: "check-mark-circle-lined",
          // eslint-disable-next-line no-undefined
          expireDate: DateTime.now().plus({second: 5}).toISO() ?? undefined
        });
        dispatch(commonStore.actions.setHashedPassword(data));
      })
      .catch(onError)
      .finally(() => {
        setFormPending(false);
      });
    }, 700);
  }

  // Render
  return !hashedPassword ? (
    <div className={classNames("page", styles['auth-page'])}>
      <h1>Войти в Popov CRM</h1>

      <form onSubmit={onAuth}>
        <div className="field-wrapper">
          <div className="label">Логин:</div>
          <input type="text" name="username" />
        </div>

        <div className="field-wrapper">
          <div className="label">Пароль:</div>
          <input type="password" name="password" />
        </div>

        <hr />

        <div className="field-wrapper">
          <button disabled={isFormPending}>
            {isFormPending ? (
              <Loader color="#fff" size={18} width={2} spinsPerSecond={1.5} />
            ) : (
              <Icon icon="lock-14" />
            )}
            <span>Войти</span>
          </button>
        </div>
      </form>
    </div>
  ) : (<Navigate to={"/"} />);
}
