import classNames from "classnames";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Loader from "../../components/Loader";
import ModalWindow from "../../components/ModalWindow";
import Select from "../../components/Select";
import Table from "../../components/Table";
import axiosClient from "../../helpers/axiosClient";
import {getPrice} from "../../helpers/misc";
import onError from "../../helpers/onError";
import BookingModel from "../../models/Booking";

import styles from './index.module.css';

const timeParts = ['day', 'week', 'month', 'year'];
const sortBys = ['person.lastName', 'checkInAt'];

const ruTimeParts: {[key: string]: string} = {
  'day': 'День',
  'week': 'Неделя',
  'month': 'Месяц',
  'year': 'Год'
};

const ruSortBys: {[key: string]: string} = {
  'person.lastName': 'ФИО',
  'checkInAt': 'Дата зеселения'
};

export default function BookingsPage() {

  // State
  const [timePart, setTimePart] = useState<string>('day');
  const [sortBy, setSortBy] = useState<string>('checkInAt');
  const [activeBooking, setActiveBooking] = useState<BookingModel | null>(null);

  /* Load bookings */
  const [bookings, setBookings] = useState<BookingModel[]>([]);
  const [bookingsLoadingErrored, setBookingsLoadingErrored] = useState(false);
  const [bookingsLoadingPending, setBookingsLoadingPending] = useState(false);
  const [loadedAllBookings, setLoadedAllBookings] = useState(false);
  const [sentryRef] = useInfiniteScroll({
    loading: bookingsLoadingPending,
    hasNextPage: !loadedAllBookings,
    // eslint-disable-next-line no-use-before-define
    onLoadMore: loadBookings,
    disabled: bookingsLoadingErrored
  });

  async function loadBookings(fromStart = false) {

    if(fromStart) {
      setBookings([]);
      setLoadedAllBookings(false);

      return;
    }

    if(bookingsLoadingPending) {
      return;
    }

    setBookingsLoadingPending(true);

    try {
      const {data: _bookings} = await axiosClient.get<BookingModel[]>("/bookings", {
        params: {
          offset: bookings.length,
          timePart, sortBy
        }
      });

      if(_bookings.length < 50) {
        setLoadedAllBookings(true);
      }

      setBookings([...bookings, ..._bookings]);
    }
    catch(error) {
      setBookingsLoadingErrored(true);
      onError(error);
    }
    finally {
      setBookingsLoadingPending(false);
    }
  }

  // Update list on options change
  useEffect(() => {
    loadBookings(true);
  }, [timePart, sortBy]);

  // Render
  return (
    <div className={classNames("page", styles['bookings-page'])} style={{maxWidth: 1400}}>

      <h2>Брони (таблица)</h2>

      <div className="list-controls">
        <div className="field-wrapper">
          <div className="label">Показывать за</div>

          <Select
            options={timeParts.map((timePart) => (
              {value: timePart, element: ruTimeParts[timePart]}
            ))} value={timePart} onTriggered={setTimePart}
          />
        </div>

        <div className="field-wrapper" style={{marginLeft: 'auto'}}>
          <div className="label">Сортировать по</div>

          <Select
            options={sortBys.map((sortBy) => (
              {value: sortBy, element: ruSortBys[sortBy]}
            ))} value={sortBy} onTriggered={setSortBy}
          />
        </div>
      </div>

      <hr />

      <Table
        headers={[
          'ФИО', 'Телефон', 'Количество ночей', 'Стоимость', 'Заселение', 'Тип капсулы'
        ]}
        data={bookings.map((booking) => ([
          `${booking.person?.lastName} ${booking.person?.firstName} ${booking.person?.middleName}`,
          `${booking.person?.phoneNumber}`,
          `${DateTime.fromISO(booking.checkOutAt)
          .diff(DateTime.fromISO(booking.checkInAt), 'days')
          .get('days')
          .toFixed(0)}`,
          `${getPrice((booking.capsuleType?.prices || []), DateTime.fromISO(booking.checkOutAt)
          .diff(DateTime.fromISO(booking.checkInAt), 'days')
          .get('days')).toLocaleString('ru')} рублей`,
          `${DateTime.fromISO(booking.checkInAt).toFormat('D')}`,
          `${booking.capsuleType?.displayName}`
        ]))}
        onRowClick={(i) => setActiveBooking(bookings[i])}
      />

      {/* Until loaded */}
      {bookingsLoadingPending ? (
        <div className="loader-container">
          <Loader color="#aaa" size={30} width={3} spinsPerSecond={3} />
        </div>
      ) : null}

      {/* When empty list */}
      {!bookingsLoadingPending && !bookings.length && loadedAllBookings ? (
        <div
          style={{
            marginTop: 20,
            textAlign: "center",
            fontStyle: "italic"
          }}
        >
          По данному запросу броней не найдено
        </div>
      ) : null}

      {!loadedAllBookings ? (
        <div ref={sentryRef} />
      ) : null}

      {/* Booking show window */}
      <ModalWindow
        title="Информация о брони"
        isShown={!!activeBooking}
        onClose={() => setActiveBooking(null)}
        isWide={true}
      >
        <div className={classNames("flex", "flex-wrap", styles['booking-window'])}>

          {activeBooking?.person ? (
            <div>
              <h3>Информация о госте:</h3>

              <div className="data-rows">
                <div className="row">
                  <div>ФИО:</div>
                  <div>
                    {`${activeBooking.person.lastName} ${activeBooking.person.firstName} ${activeBooking.person.middleName}`}
                  </div>
                </div>
                <div className="row">
                  <div>Дата рождения:</div>
                  <div>
                    {DateTime.fromISO(activeBooking.person.birthDate).toFormat('DD')}
                  </div>
                </div>
                <div className="row">
                  <div>Номер телефона:</div>
                  <div>
                    {activeBooking.person.phoneNumber}
                  </div>
                </div>

                <div className="row">
                  <div>Серия и номер паспорта:</div>
                  <div>
                    {`${activeBooking.person.passportSeries} ${activeBooking.person.passportNumber}`}
                  </div>
                </div>

                <div className="row">
                  <div>Кем выдан паспорт:</div>
                  <div>
                    {`${activeBooking.person.passportIssuerCode}, ${activeBooking.person.passportIssuedBy}`}
                  </div>
                </div>

                <div className="row">
                  <div>Дата выдачи паспорта:</div>
                  <div>
                    {DateTime.fromISO(activeBooking.person.passportIssuedAt).toFormat('DD')}
                  </div>
                </div>

                <div className="row">
                  <div>Адрес прописки:</div>
                  <div>
                    {activeBooking.person.legalAddress}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {activeBooking ? (
            <div>
              <h3>Информация о брони:</h3>

              <div className="data-rows">
                <div className="row">
                  <div>Номер капсулы:</div>
                  <div>{activeBooking.capsule?.number}</div>
                </div>
                <div className="row">
                  <div>Заселение в:</div>
                  <div>{DateTime.fromISO(activeBooking.checkInAt).toFormat('D')}</div>
                </div>
                <div className="row">
                  <div>Выселение до:</div>
                  <div>{DateTime.fromISO(activeBooking.checkOutAt).toFormat('D')}</div>
                </div>
                <div className="row">
                  <div>Количество ночей:</div>
                  <div>{DateTime.fromISO(activeBooking.checkOutAt)
                  .diff(DateTime.fromISO(activeBooking.checkInAt), 'days')
                  .get('days')}</div>
                </div>
                <div className="row">
                  <div>Стоимость:</div>
                  <div>
                    {`${getPrice((activeBooking.capsuleType?.prices || []), DateTime.fromISO(activeBooking.checkOutAt)
                    .diff(DateTime.fromISO(activeBooking.checkInAt), 'days')
                    .get('days')).toLocaleString('ru')} рублей`}
                  </div>
                </div>
                <div className="row">
                  <div>Тип капсулы:</div>
                  <div>{activeBooking.capsuleType?.displayName}</div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </ModalWindow>
    </div>
  );
}
