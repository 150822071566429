export function randomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export function generateHex(length: number) {
  let hex = "";

  for(let i = 0; i < length; i++) {
    hex += randomNumber(0, 15).toString(16);
  }

  return hex;
}

export const asyncSleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export function getPrice(prices: number[], hoursAmount: number) {
  let sum = 0;
  let _amount = hoursAmount;

  const _prices = [0, ...prices];

  while(_amount >= 0) {

    if(_amount > _prices.length - 1) {
      _amount -= _prices.length - 1;
      sum += _prices[_prices.length - 1];
    }
    else {
      sum += _prices[_amount];
      break;
    }
  }

  return sum;
}
